import React, { useEffect, useState } from "react";
import i18n, { t } from "i18next";
import AppStandardButton from "../../components/UI/AppButtons";
import BookingGetters from "../../store/onlineBooking/getters";
import { useSearchParams } from "react-router-dom";
import TagManager from "react-gtm-module";
import { TextResource } from "../../@types/DTO/AppSetting";
import { formatTheme } from "../../config/MuiColorThemes";
import { createTheme } from "@mui/material/styles";
import { BookingApi } from "../../API/Apis/BookingApi";

export function BookingConfirmationPayPal() {
  const appSettings = BookingGetters.getAppSettings();
  const redirectWebCheckIn = appSettings?.redirectWebCheckIn;
  const HideOneButton =
    appSettings && appSettings?.hideButtonAtConfirmationPage;
  const [searchParams] = useSearchParams();
  const price = searchParams.get("amount");
  const dbId = searchParams.get("dbId");
  const ResId = searchParams.get("ResId");
  console.log("paypalResId", ResId);
  const [showGtagManagerEvent, setGtagManagerEvent] = useState(
    appSettings?.gtagManagerEvent || ""
  );
  const showResPrintButton = appSettings && appSettings?.showResPrintButton;
  const [showGTagManagerSendTo, setGTagManagerSendTo] = useState(
    appSettings?.gTagManagerSendTo || ""
  );
  const [showGTagManagerCurrency, setGTagManagerCurrency] = useState(
    appSettings?.gTagManagerCurrency || ""
  );
  const [isTagManagerDataLayerEnabled, setIsTagManagerDataLayerEnabled] =
    useState(false);
  const [tagManagerId, setTagManagerId] = useState("");
  const [isTagManagerEnabled, setIsTagManagerEnabled] = useState(false);
  const [showGtagManagerValue, setGtagManagerValue] = useState(false);
  const [showGTagManagerTransactionId, setGTagManagerTransactionId] =
    useState(false);
  const showPrice = showGtagManagerValue ? price : null;
  const showDbId = showGTagManagerTransactionId ? dbId : null;
  const textResource = BookingGetters.getTextResource();
  const [confirmationPageLabel, setConfirmationPageLabel] =
    useState<String>("");
  useEffect(() => {
    const data: TextResource[] = textResource?.data ?? [];
    const confirmationPageTextData = data.find(
      (item) => item.key === "ConfirmationPageTextLabel"
    );
    setConfirmationPageLabel(
      confirmationPageTextData?.body ||
        t("Congratulations! Your Booking Is Completed")
    );
  });
  useEffect(() => {
    setIsTagManagerDataLayerEnabled(!!appSettings?.enableDataLayer);
    setGTagManagerCurrency(appSettings?.gTagManagerCurrency || "");
    setGTagManagerSendTo(appSettings?.gTagManagerSendTo || "");
    setGtagManagerEvent(appSettings?.gtagManagerEvent || "");
    setGtagManagerValue(!!appSettings?.enableGtagManagerValue);
    setGTagManagerTransactionId(!!appSettings?.enableGTagManagerTranscationId);
    setIsTagManagerEnabled(!!appSettings?.enableTagManager);
    setTagManagerId(appSettings?.tagManagerId || "");
  }, [appSettings]);

  useEffect(() => {
    if (isTagManagerDataLayerEnabled) {
      const tagManagerArgs = {
        dataLayer: {
          event: showGtagManagerEvent,
          send_to: showGTagManagerSendTo,
          value: showPrice,
          currency: showGTagManagerCurrency,
          transaction_id: showDbId,
        },
      };
      TagManager.dataLayer(tagManagerArgs);
    }
  }, [isTagManagerDataLayerEnabled]);
  useEffect(() => {
    if (isTagManagerEnabled && tagManagerId != "") {
      const tagManagerArgs = {
        gtmId: tagManagerId,
      };
      TagManager.initialize(tagManagerArgs); // google tag manager integrated along with the preferences
    }
  }, [tagManagerId, isTagManagerEnabled]);
  const dynamicPalette = formatTheme();
  const dynamicTheme = createTheme(dynamicPalette);
  const primaryColor = dynamicTheme?.palette["primary"]?.main;
  const secondaryColor = dynamicTheme?.palette["secondaryColor"]?.main;
  const confirmationTextColor =
    dynamicTheme?.palette["confirmationTextColor"]?.contrastText;

  return (
    <section className="min-h-[80vh] box-border px-10 md:px-44  flex flex-col border-t-2 border-solid border-grey-300">
      <header className="grow flex flex-col items-center justif-center gap-4 box-border py-8">
        <img src="/assets/images/success-icon@2x.png" className="h-36 w-36" />
        <h1
          className="text-3xl font-bold py-2"
          style={{
            color: confirmationTextColor
              ? confirmationTextColor
              : secondaryColor,
          }}
        >
          {confirmationPageLabel}
        </h1>
        <p className="font-bold text-black-600"></p>
        <div className="flex flex-col-2 ">
          {" "}
          <AppStandardButton
            href="/embed"
            className={"w-fit py-4 mx-0 md:mx-8"}
            variant="outlined"
            /*  color="primary"*/
            style={{ borderRadius: "5", color: primaryColor }}
          >
            <span
              className="normal-case p-1 text-lg"
              style={{ color: secondaryColor }}
            >
              {" "}
              Homepage
            </span>
          </AppStandardButton>
          {!redirectWebCheckIn
            ? !HideOneButton && (
                <AppStandardButton
                  href="/embed"
                  className={"w-fit py-4 mx-2 md:mx-8"}
                  /*color="primary"*/
                  variant="outlined"
                  style={{ borderRadius: "5" }}
                >
                  <span
                    className="normal-case  p-1 text-lg"
                    style={{ color: secondaryColor, color: primaryColor }}
                  >
                    {" "}
                    Dashboard
                  </span>
                </AppStandardButton>
              )
            : !HideOneButton && (
                <AppStandardButton
                  href={`/booking/web-check-in?lang=${i18n.language}`}
                  className={"w-fit py-4 mx-2 md:mx-8"}
                  /*   color="primary"*/
                  variant="outlined"
                  style={{ borderRadius: "5", color: primaryColor }}
                >
                  <span
                    className="normal-case  p-1 text-lg whitespace-nowrap"
                    style={{ color: secondaryColor }}
                  >
                    {" "}
                    Web Check-In
                  </span>
                </AppStandardButton>
              )}
          {showResPrintButton && (
            <AppStandardButton
              onClick={() => {
                BookingApi.getResPrint(ResId || "").then((e) => {
                  const url = e?.file;
                  const linkSource = `data:application/pdf;base64,${url}`;
                  const downloadLink = document.createElement("a");
                  const fileName = "file.pdf";
                  downloadLink.href = linkSource;
                  downloadLink.download = fileName;
                  downloadLink.click();
                });
              }}
              className={"w-fit py-4 mx-2 md:mx-8"}
              variant="outlined"
              style={{ borderRadius: "5", color: primaryColor }}
            >
              <span
                className="normal-case p-1 text-lg whitespace-nowrap"
                style={{ color: secondaryColor }}
              >
                {" "}
                Print Reservation
              </span>
            </AppStandardButton>
          )}
        </div>
      </header>
      <main></main>
      <footer className="text-gray-600 box-border pb-32">
        <div className="box-border border-2 border-solid border-grey-200 px-8"></div>
      </footer>
    </section>
  );
}
