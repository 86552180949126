import {
  BookingVehicleInfoCardProps,
  ChosenOptions,
  VehicleSpecsCardProps,
} from "./BookingVehicleInfoCard.props";
import React, { createContext, useEffect, useState } from "react";

import AppButton from "../../UI/AppButtons/AppButton";
import BookingGetters from "../../../store/onlineBooking/getters";
import { Carousel } from "../Carousel/Carousel";
import Insurance from "../../../API/responses/Insurance.json";
import { InsuranceCardsLayout } from "../Insurance/Insurance";
import { Modal } from "../../UI/AppModal";
import { PageConfigGetters } from "../../../store/PageConfigurations/getters";
import SelectVehicleExtra from "../VehicleExtraSelections/SelectVehicleExtra";
import { TabView } from "../../UI/AppTabView";
import { VehicleSpecification } from "../VehicleSpecification/VehicleSpecification";
import { formatPriceWithCurrencySymbol } from "../../../helpers/getPriceWithCurrencySymbol";
import theme from "../../../config/MuiColorThemes";
import { SelectedVehicleDetails } from "../../../@types/Booking/SelectedVehicleDetails";
import {
  differenceInDays,
  differenceInHours,
  format,
  startOfDay,
} from "date-fns";
import BookingApi from "../../../API/Apis/BookingApi";
import { InsuranceDetails } from "../../../@types/DTO/ApiResponses";
import { AppRadioButton } from "../../UI/AppRadioButtons/AppRadioButtons";
import { Vehicle } from "../../../@types/DTO/Vehicles";
import { t } from "i18next";
import { useSelector } from "react-redux";
import { BookingStateRedux } from "../../../store/onlineBooking/states";
import { Button, Grid, Typography } from "@mui/material";
import "animate.css";
import { useTranslation } from "react-i18next";

function BookingVehicleInfoCard({
  status = "Unavailable",

  ...VehicleDetails
}: BookingVehicleInfoCardProps) {
  const ReservationDetails = BookingGetters.getBookingDetails();
  const [selectedImage] = useState<number>(0);
  const [selectedFields] = useState<ChosenOptions>({
    insurance: undefined,
    options: {},
  });
  const { t: translate } = useTranslation();
  const [startDate] = useState(
    new Date(
      format(
        new Date(ReservationDetails.pickUpDateAndTime || Date.now()),
        "yyyy-MM-dd"
      )
    )
  );
  const [endDate] = useState(
    new Date(
      format(
        new Date(ReservationDetails.dropOffDateAndTime || Date.now()),
        "yyyy-MM-dd"
      )
    )
  );
  const [numberOfDays] = useState(differenceInDays(endDate, startDate));
  const [differenceInHour] = useState(differenceInHours(endDate, startDate));
  const approximateDays = Math.ceil(differenceInHour / 24);
  const [pricePerDay] = useState(
    parseFloat(VehicleDetails.priceDetails.payNow.toString()) /
      (approximateDays == 0 ? 1 : approximateDays)
  );
  const [totalPricePerDay, setTotalPricePerDay] = useState(pricePerDay);
  const [discount, setDiscount] = useState(
    (100 - VehicleDetails.discount) / 100
  );
  const [totalPrice, setTotalPrice] = useState<number>(
    parseFloat(VehicleDetails.priceDetails.payNow) * discount
  );
  const [totalPayLaterPrice, setTotalPayLaterPrice] = useState<number>(
    parseFloat(VehicleDetails.priceDetails.payLater)
  );
  const [reservationSourceLater, setReservationSourcePayLater] =
    useState<string>(VehicleDetails.reservationSourcePayLater || "");
  const [paymentType] = useState<"PAYNOW" | "PAYLATER" | undefined>();
  const [showInfo, setShowInfo] = useState(false);
  const toogleVehicleInfoCardShowingStatus = () => {
    setShowInfo(!showInfo);
  };
  const payNowDisAmount =
    (parseFloat(VehicleDetails.priceDetails.payLater.toString()) *
      VehicleDetails.discount) /
    100;

  useEffect(() => {
    setDiscount((100 - VehicleDetails.discount) / 100);
  }, [VehicleDetails.discount]);

  useEffect(() => {
    let price: number = parseFloat(
      selectedFields.insurance?.price?.toString() || "0"
    );
    if (selectedFields.options) {
      Object.values(selectedFields.options).map((el) => {
        price += el.price * el.quantity;
      });
    }
    const totalPrice =
      reservationSourceLater == "true"
        ? totalPayLaterPrice
        : parseFloat(VehicleDetails.priceDetails.payNow) * discount;
    const totalPriceAfterExtra = price + totalPrice;
    setTotalPricePerDay(
      totalPriceAfterExtra / (approximateDays == 0 ? 1 : approximateDays)
    );
    setTotalPrice(totalPriceAfterExtra);
  }, [selectedFields]);

  useEffect(() => {
    setTotalPrice(
      reservationSourceLater == "true"
        ? totalPayLaterPrice
        : parseFloat(VehicleDetails.priceDetails.payNow) * discount
    );
    setTotalPricePerDay(
      reservationSourceLater == "true"
        ? totalPayLaterPrice / (approximateDays == 0 ? 1 : approximateDays)
        : parseFloat(
            (
              parseFloat(VehicleDetails.priceDetails.payNow) * discount
            ).toString()
          ) / (approximateDays == 0 ? 1 : approximateDays)
    );
  }, [VehicleDetails.priceDetails]);

  useEffect(() => {
    if (reservationSourceLater === "true") {
      setTotalPrice(totalPayLaterPrice);
      setTotalPricePerDay(
        totalPayLaterPrice / (approximateDays === 0 ? 1 : approximateDays)
      );
    }
  }, []);

  const appSettings = BookingGetters.getAppSettings();
  const defaultCurrency = appSettings?.defaultCurrency;
  const scrollToTop = () => {
    window.parent.postMessage(
      {
        event_id: "scrollToTop",
      },
      "*"
    );
  };
  function BookButton() {
    return (
      <AppButton
        disabled={status.toLowerCase() === "unavailable"}
        color="primary"
        className="w-[6rem] h-[3rem] rounded-md"
        clickHandler={() => {
          window.scrollTo(0, 0);
          scrollToTop();
          VehicleDetails.moveToPayment({
            isPayLaterFee: false,
            extrasIncluded: false,
            extrasPrice: 0,
            payLaterFeeAmt: "",
            isDiscounted: payNowDisAmount,
            type: paymentType as "PAYNOW" | "PAYLATER",
            price: totalPrice,
            addedExtraOptions: selectedFields.options,
            selectedInsurance: selectedFields.insurance,
          });
        }}
      >
        <p
          className={`min-w-24 w-full h-full flex items-center justify-center text-${VehicleDetails.textColor} md:text-x text-x capitalize font-normal`}
        >
          {translate("Select")}
        </p>
      </AppButton>
    );
  }
  return (
    <section
      className="hover:drop-shadow-lg cursor-pointer rounded-tl-[32px] rounded-br-[12px] rounded-bl-[12px] relative flex flex-col bg-white  box-border font-roboto  text-[#004188] z-12"
      style={{ backgroundColor: VehicleDetails.backgroundColor }}
    >
      <header
        className={
          "text-black w-full  flex md:flex-row  flex-col p-2 px-4 bg-[#f1f1f1] rounded-tl-[32px] rounded-br-[32px] "
        }
      >
        <aside className={"flex flex-row"}>
          <div className="w-full h-full p-2 flex items-center justify-center">
            {selectedImage >= 0 && (
              <img
                src={VehicleDetails.vehicleImages[selectedImage]}
                className={"md:min w-full aspect-video object-contain h-48"}
                alt={""}
              />
            )}
          </div>
        </aside>
      </header>
      <footer className="gap-2 box-border p-2 ml-2 mb-1">
        <div className={"md:flex flex-wrap gap-2"}>
          <div className="md:gap-8 gap-0 w-full">
            {appSettings?.showOnrequestVehicles &&
            VehicleDetails?.vehicleStatus?.toLowerCase() == "onrequest" ? (
              <div
                className="box-border text-[12px] text-black capitalize font-bold"
                style={{ color: "rgba(0, 0, 0, 0.6)", textAlign: "left" }}
              >
                {t(
                  "Vehicle with limited availability - subjected to confirmation"
                )}
              </div>
            ) : (
              ""
            )}
            <div className="flex justify-between items-center">
              <div
                className="box-border py-2 md:text-lg text-lg capitalize font-bold"
                style={{ color: VehicleDetails.backgroundTextColor }}
                title={
                  VehicleDetails.vehicleName.split(" or ")[0].length > 23
                    ? VehicleDetails.vehicleName.split(" or ")[0]
                    : undefined
                }
              >
                {VehicleDetails.vehicleName
                  .split(" or ")[0]
                  .substring(0, 23)
                  .toLowerCase()}{" "}
                {VehicleDetails.vehicleName.split(" or ")[0].length > 23 &&
                  ".."}
              </div>
              <div className="h-full width-full flex flex-row z-8">
                <Button onClick={toogleVehicleInfoCardShowingStatus}>
                  <p
                    className="text-md capitalize"
                    style={{
                      color: VehicleDetails.backgroundTextColor,
                    }}
                  >
                    {t("Info")}
                  </p>
                </Button>
              </div>
            </div>
            <div style={{ float: "right" }}>
              <p className="text-[primary] text-md capitalize font-bold">
                <BookButton />
              </p>
            </div>

            <div className={"grid gap-2 mb-1 justify-items-start box-border "}>
              {(() => {
                const amountDetails = formatPriceWithCurrencySymbol(
                  BookingGetters.getCurrency(),
                  totalPricePerDay
                );
                return (
                  <span className="main-price text-inherit md:text-xl text-md">
                    <p className={"whitespace-nowrap text-black"}>
                      {(defaultCurrency || amountDetails.symbol) +
                        " " +
                        amountDetails.price}
                      <sub className="font-normal h-full">
                        {" "}
                        {translate("/day")}
                      </sub>
                    </p>
                  </span>
                );
              })()}
              {(() => {
                const amountDetails = formatPriceWithCurrencySymbol(
                  BookingGetters.getCurrency(),
                  totalPrice
                );
                return (
                  <span className="total-price md:text-md text-sm text-[#707070] whitespace-nowrap font-normal">
                    <span>{translate("Total")} </span>
                    <span>{amountDetails.symbol + " "}</span>
                    <span>{amountDetails.price}</span>
                  </span>
                );
              })()}
            </div>
          </div>
          <div className="flex justify-between items-center">
            <Grid container spacing={0}>
              {VehicleDetails?.addedOOHFee && (
                <Grid item xs={12}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: "small",
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    Includes {defaultCurrency || "€"}
                    {"  "}
                    {VehicleDetails?.addedOOHFee} of OOH Fee
                  </Typography>
                </Grid>
              )}
              {VehicleDetails?.addedOnewayFee && (
                <Grid item xs={12}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: "small",
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    Includes {defaultCurrency || "€"}
                    {"  "}
                    {VehicleDetails?.addedOnewayFee?.toFixed(2)} of oneway fee
                  </Typography>
                </Grid>
              )}
              {VehicleDetails?.addedOnewayOOHFee && (
                <Grid item xs={12}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: "small",
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    Includes {defaultCurrency || "€"}
                    {"  "}
                    {VehicleDetails?.addedOnewayOOHFee} of oneway and OOH Fee
                  </Typography>
                </Grid>
              )}
              {VehicleDetails?.addedYoungDriverFee && (
                <Grid item xs={12}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: "small",
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    Includes {defaultCurrency || "€"}
                    {"  "}
                    {VehicleDetails?.addedYoungDriverFee} of Y_D fee
                  </Typography>
                </Grid>
              )}
              {VehicleDetails?.addedYoungDriver2Fee && (
                <Grid item xs={12}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: "small",
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    Includes {defaultCurrency || "€"}
                    {"  "}
                    {VehicleDetails?.addedYoungDriver2Fee} of Y_D_2 fee
                  </Typography>
                </Grid>
              )}
              {VehicleDetails?.addedSeniorDriverFee && (
                <Grid item xs={12}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: "small",
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    Includes {defaultCurrency || "€"}
                    {"  "}
                    {VehicleDetails?.addedSeniorDriverFee} of S_D fee
                  </Typography>
                </Grid>
              )}
              {VehicleDetails?.addedNoAgeFee && (
                <Grid item xs={12}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: "small",
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    Includes €{VehicleDetails?.addedNoAgeFee} of No age fee
                  </Typography>
                </Grid>
              )}
            </Grid>
          </div>
          <div className="w-full border-solid border-2"></div>
          <div className="flex flex-row flex-wrap w-fit gap-8 ml-4 items-center">
            {Array.from({
              length:
                VehicleDetails.specifications.length > 4
                  ? 4
                  : VehicleDetails.specifications.length,
            }).map((_, index) => {
              const el = VehicleDetails.specifications[index];
              return (
                <div key={index} className="h-fit w-fit text-sm">
                  {el ? (
                    <VehicleSpecsCard {...el} />
                  ) : (
                    <div className="w-10 h-10"></div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </footer>
      {showInfo && appSettings?.vehicleInfoChoice == "baseExcessAndDepositVal" && (
        <div
          style={{ overflowY: "auto" }}
          className="hover:drop-shadow-lg cursor-pointer rounded-tl-[32px] rounded-br-[12px] rounded-bl-[12px] h-full w-full absolute bg-white p-4 z-10 top-0 left-0 animate__animated animate__zoomIn"
        >
          <div>
            <Button
              className="w-1/3 flex justify-end"
              style={{ color: VehicleDetails.backgroundTextColor }}
              onClick={() => {
                toogleVehicleInfoCardShowingStatus();
              }}
            >
              {translate("Close")}
            </Button>
            <div className="h-fit flex flex-row flex-wrap w-fit gap-12 ml-4">
              {/*<span className="h-fit flex items-left text-black md:text-md text-md">*/}
              {/*  {"Base Excess Value"}*/}
              {/*</span>*/}
            </div>
            <div className="h-fit items-left flex-wrap w-fit gap-12 ml-4 my-2">
              <span className="items-left font-light text-black md:text-md text-md">
                <p className="my-2 flex items-left">
                  {translate("theft.excess.amount")}{" "}
                  <span className="mx-4" style={{ fontWeight: "bold" }}>
                    {"€"}
                    {VehicleDetails?.theftExcessAmount}
                  </span>
                </p>
                <p className="my-2 flex items-left">
                  {translate("damage.excess.amount")}
                  <span className="mx-4" style={{ fontWeight: "bold" }}>
                    {"€"}
                    {VehicleDetails?.damageExcessAmount}
                  </span>
                </p>
                <p className="flex items-left">
                  {translate("deposit.excess.amount")}
                  <span className="mx-4" style={{ fontWeight: "bold" }}>
                    {"€"} {VehicleDetails?.depositAmtFromOpt}
                  </span>
                </p>
              </span>
            </div>
          </div>
        </div>
      )}
      {showInfo && appSettings?.vehicleInfoChoice == "includedOptional" && (
        <div
          style={{ overflowY: "auto" }}
          className="hover:drop-shadow-lg cursor-pointer rounded-tl-[32px] rounded-br-[12px] rounded-bl-[12px] h-full w-full absolute bg-white p-4 z-10 top-0 left-0 animate__animated animate__zoomIn"
        >
          <div>
            <Button
              className="w-1/3 flex justify-end"
              style={{ color: VehicleDetails.backgroundTextColor }}
              onClick={() => {
                toogleVehicleInfoCardShowingStatus();
              }}
            >
              {translate("Close")}
            </Button>
            <div className="h-fit flex flex-row flex-wrap w-fit gap-12 ml-4">
              {(() => {
                const extras = VehicleDetails.extras;
                const included: string[] = [];
                const notIncluded: string[] = [];

                extras.forEach((el) => {
                  if (
                    el.Charge.IncludedInRate &&
                    el.Charge.Description != null
                  ) {
                    included.push(el.Charge.Description);
                  } else if (el.Charge.Description != null) {
                    notIncluded.push(el.Charge.Description);
                  }
                });

                return (
                  <>
                    <span className="font-light text-gray-600 text-sm text-left">
                      <h2
                        className="text-md font-bold"
                        style={{ color: VehicleDetails.backgroundTextColor }}
                      >
                        {included.length > 0 && (
                          <span>{translate("Including")}</span>
                        )}
                      </h2>
                      <br></br>
                      {included.map((val, index) => (
                        <div key={index}>
                          <h3>{val}</h3>
                        </div>
                      ))}
                    </span>
                    <span className="font-light text-gray-600 text-sm text-left">
                      <h2
                        className="text-md text-[#17A1B7] font-bold"
                        style={{ color: VehicleDetails.backgroundTextColor }}
                      >
                        {notIncluded.length > 0 && (
                          <span>{translate("Not Including")}</span>
                        )}
                      </h2>
                      <br></br>
                      {notIncluded.map((val, index) => (
                        <div key={index}>{val}</div>
                      ))}
                    </span>
                  </>
                );
              })()}
            </div>
          </div>
        </div>
      )}
      {showInfo && appSettings?.vehicleInfoChoice == "vehicleParameters" && (
        <div
          style={{ overflowY: "auto" }}
          className="hover:drop-shadow-lg cursor-pointer rounded-tl-[32px] rounded-br-[12px] rounded-bl-[12px] h-full w-full absolute bg-white p-4 z-10 top-0 left-0 animate__animated animate__zoomIn"
        >
          <div>
            <Button
              className="w-1/3 flex justify-end"
              style={{ color: VehicleDetails.backgroundTextColor }}
              onClick={() => {
                toogleVehicleInfoCardShowingStatus();
              }}
            >
              {translate("Close")}
            </Button>
          </div>
          {VehicleDetails.specifications.map((el, index) => {
            return (
              <div
                key={index}
                style={{ flexBasis: "calc(33.33% - 8px)" }} // Adjust the flex basis to create a 3-column layout
              >
                <VehicleSpecification {...el} />
              </div>
            );
          })}
        </div>
      )}
    </section>
  );
}

function VehicleSpecsCard({ iconUrl, value }: VehicleSpecsCardProps) {
  return (
    <div className="w-full min-w-fit flex flex-row gap-2">
      <div className="flex items-stretch">
        {iconUrl != "" && iconUrl != undefined && (
          <img className="h-8 w-8" src={iconUrl} alt={iconUrl} />
        )}
      </div>
      <span className="h-fit flex items-left font-light text-black md:text-md text-md mt-2 -ml-2">
        {value}
      </span>
    </div>
  );
}

export { BookingVehicleInfoCard };
